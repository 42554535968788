import React, { Component } from 'react';
import './../styles/Client.scss';
import { HashRouter as Router, Switch, Redirect } from 'react-router-dom';
import moment from 'moment';

import PickTime from '../components/public/PickTime';
import PickDate from '../components/public/PickDate';
import Template from '../components/public/Template';
import PropsRoute from '../utils/PropsRoute';
import Contact from '../components/public/Contact';
import NbGuest from '../components/public/NbGuest';
import Menu from '../components/public/Menu';
import Validation from '../components/public/Validation';
import Header from '../components/public/Header';
import CallApi from '../utils/CallApi';
import BeingCreated from '../components/public/BeingCreated';
import LegalInformation from '../components/public/LegalInformation';
import TemplateMobile from '../components/public/TemplateMobile';
import { AdminManager, getCookie } from '../utils/function';
import NotFound from '../components/public/NotFound';
// import { startLogRocketSession } from '../utils/Logrocket';
import AdminInfo from '../components/public/AdminInfo';
import { initLogRocket, startLogRocketSession } from '../utils/Logrocket';

class IndexUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			info: {
				nameRestaurant: '',
				restaurantId: '' /* props.match.params.id */,
				streetNumber: '',
				street: '',
				additionalAddress: '',
				zipcode: '',
				city: '',
				country: '',
				phone: '',
				slogan: '',
				closingDays: [],
				openingHours: {},
				validationDelay: 0,
				email: '',
				disableApp: false,
				disableAppPeriod: {},
				disableMobilePeriod: '',
				specificInformation: '',
				timezone: '',
				maxGuests: null,
			},
			mobilePeriod: '',
			closeDay: [],
			menu: {
				day: '',
				time: '',
				nbGuest: '',
				contact: '',
				send: '',
			},
			active: {
				day: false,
				time: false,
				diner: false,
				contact: false,
				legalInformation: false,
			},
			displayMenu: true,
			error: '',
			width: '',
			sendBooking: false,
			slot_duration: 15,
			slot_freeze: {},
			logRocket: false,
		};
	}

	componentWillMount() {
		this.hydrateStateWithLocalStorage();
		this.handleWindowSizeChange();

		window.addEventListener('beforeunload', this.saveStateToLocalStorage);
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.saveStateToLocalStorage);
		window.removeEventListener('resize', this.handleWindowSizeChange);
		this.saveStateToLocalStorage();
	}

	async componentDidMount() {
		const pathname = window.location.pathname;
		const segmentURL = pathname.split('/');
		const restaurantId = segmentURL[2];
		try {
			const originalId = await CallApi(
				'get',
				'restaurants/get-id',
				restaurantId
			);
			const res = await CallApi(
				'get',
				'restaurants',
				originalId[0].restaurantId
			);
			const result = res[0];

			// pour le mode admin
			const token = getCookie('refreshToken');
			await AdminManager.setAdmin(token, result);
			const openingHours =
				result.openingDate && result.openingDate.monday
					? result.openingDate
					: JSON.parse(result.openingDate);
			let checkIfOpeningHours = this.checkHoursNotEmpty(openingHours);

			let slot_freeze = {};
			try {
				if (result.slot_freeze) slot_freeze = JSON.parse(result.slot_freeze);
			} catch (e) {}

			if (checkIfOpeningHours) {
				this.setState({
					info: {
						//keep the openingHours data if reload the page
						...this.state.info,
						restaurantId: result.id,
						nameRestaurant: result.name,
						streetNumber: result.address.streetNumber,
						street: result.address.street,
						additionalAddress: result.address.additionalAddress,
						zipcode: result.address.zipcode,
						city: result.address.city,
						country: result.address.country,
						phone: result.phone,
						slogan: result.slogan,
						closingDays: result.closingDays,
						openingHours: result.openingDate.monday
							? result.openingDate
							: JSON.parse(result.openingDate),
						validationDelay: result.validationDelay,
						email: result.emailContact,
						disableApp: result.disableApp,
						specificInformation: result.specificInformation,
						disableAppPeriod: result.disableAppPeriod
							? JSON.parse(result.disableAppPeriod)
							: result.disableAppPeriod,
						timezone: result.timezone,
						maxGuests: result.nbrGuests,
					},
					nameRestaurant: result.name,
					error: false,
					// displayMenu: true,
					slot_duration: result.slot_duration,
					slot_freeze,
					logRocket: result.log_rocket,
				});

				/**
				 * check the day where closed
				 */
				let closeDay = [];
				for (let key in openingHours) {
					let findPeriod = openingHours[key].length ? true : false;
					if (!findPeriod) {
						closeDay.push(key);
					}
				}
				this.setState({
					closeDay: closeDay,
				});
			} else {
				this.setState({
					error: true,
					info: {
						...this.state.info,
						nameRestaurant: result.name,
						streetNumber: result.address.streetNumber,
						street: result.address.street,
						additionalAddress: result.address.additionalAddress,
						zipcode: result.address.zipcode,
						city: result.address.city,
						country: result.address.country,
						phone: result.phone,
						slogan: result.slogan,
						validationDelay: result.validationDelay,
						email: result.emailContact,
						specificInformation: result.specificInformation,
						timeone: result.timezone,
					},
					nameRestaurant: result.name,
					slot_duration: result.slot_duration,
					slot_freeze,
					logRocket: result.log_rocket,
				});
			}
		} catch (e) {
			this.setState({ error: 'probleme' });
		}

		if (this.state.logRocket) {
			initLogRocket();
			startLogRocketSession(`User View - ${this.state.info.restaurantId}`, { name: this.state.nameRestaurant})
		}
	}

	getTokenFromUrl = () => {
		const searchParams = new URLSearchParams(window.location.search);
		const token = searchParams.get('refreshToken');

		if (token && !localStorage.getItem('refreshToken')) {
			localStorage.setItem('refreshToken', token);
		}

		return token;
	};

	checkHoursNotEmpty = hours => {
		const arrayHours = Object.entries(hours);
		const openingHoursNotEmpty = arrayHours.some(day => day[1].length);
		return openingHoursNotEmpty;
	};

	hydrateStateWithLocalStorage = () => {
		const state = {};
		for (let key in this.state) {
			const item = window.localStorage.getItem(key);
			const shouldNotParse = item === 'undefined' || item === 'null' || !item;
			if (shouldNotParse) {
				return;
			}

			const value = JSON.parse(item);
			if (value) {
				if (key === 'menu' && value) {
					const resultCheck = this.checkDateLocalStorage(value);
					if (!resultCheck) {
						return;
					}
				}
				//TODO REFACTO BIS
				if (key == 'info') {
					const isOld = Object.keys(value.openingHours).some(key => {
						if (value.openingHours[key].length) {
							const openingDate = Object.keys(value.openingHours[key][0]);
							return (
								openingDate[0] === 'morning' || openingDate[0] === 'evening'
							);
						}
						return false;
					});
					if (isOld) {
						return;
					}
				}
				//TODO REFACTO BIS
				state[key] = value;
			}
		}
		this.setState({ ...state });
	};

	saveStateToLocalStorage = () => {
		for (let key in this.state) {
			//TODO REFACTO BIS
			if (key == 'info') {
				const isOld = Object.keys(this.state.info.openingHours).some(key => {
					if (this.state.info.openingHours[key].length) {
						const openingDate = Object.keys(
							this.state.info.openingHours[key][0]
						);
						return openingDate[0] === 'morning' || openingDate[0] === 'evening';
					}
					return false;
				});
				if (!isOld) {
					window.localStorage.setItem(key, JSON.stringify(this.state[key]));
				}
				//TODO REFACTO BIS
			} else {
				if (key === 'menu' && this.state[key]) {
					const resultCheck = this.checkDateLocalStorage(this.state[key]);
					if (!resultCheck) {
						return;
					}
				}
				window.localStorage.setItem(key, JSON.stringify(this.state[key]));
			}
		}
	};

	handleWindowSizeChange = () => {
		this.setState({ ...this.state, width: window.innerWidth });
	};

	checkDateLocalStorage = data => {
		let formateDate = moment(`${data.day}`);
		const now = moment();
		let typeCheck = 'day';
		if (data.time) {
			const formateTime = data.time.replace(/h/i, ':');
			formateDate = moment(`${data.day} ${formateTime}`);
			typeCheck = '';
		}
		const selectionIsAfterNow = formateDate.isSameOrAfter(now, typeCheck);
		if (!selectionIsAfterNow) {
			window.localStorage.removeItem('menu');
		}
		return selectionIsAfterNow;
	};

	callApi = async userApi => {
		const response = await fetch(`/api/restaurants/${userApi}`);
		const body = await response.json();

		if (response.status !== 200) throw Error(body.message);
		return body;
	};

	// new booking
	onUpdateDisplayMenu = () => {
		this.setState({
			displayMenu: true,
		});
	};

	onUpdateDate = val => {
		let state = {
			...this.state,
		};
		state.menu.day = moment(val).format('YYYY-MM-DD');
		state.active.day = true;
		// when change date, remove last time
		state.active.time = false;
		state.menu.time = '';

		this.setState(state, this.saveStateToLocalStorage());
	};

	onUpdateMobileDatePeriod = val => {
		let state = {
			...this.state,
		};
		state.mobilePeriod = val;
		if (val === '') {
			state.menu.time = '';
			state.active.time = false;
		}
		this.setState(state, this.saveStateToLocalStorage());
	};

	onUpdateTime = val => {
		let state = {
			...this.state,
		};
		state.menu.time = val;
		state.active.time = val ? true : false;
		this.setState(state, this.saveStateToLocalStorage());
	};

	onUpdateNbGuest = val => {
		let state = {
			...this.state,
		};
		state.menu.nbGuest = val;
		state.active.nbGuest = true;
		this.setState(state, this.saveStateToLocalStorage());
	};

	onUpdateContact = val => {
		let state = {
			...this.state,
		};
		state.menu.contact = `${val}`;
		state.active.contact = true;
		this.setState(state, this.saveStateToLocalStorage());
	};

	onUpdateMenuTime = () => {
		let state = {
			...this.state,
		};
		state.menu.time = '';
		this.setState(state, this.saveStateToLocalStorage());
	};

	disableMobilePeriod = val => {
		let state = {
			...this.state,
		};
		state.menu.disableMobilePeriod = val;
		this.setState(state);
	};

	sendBooking = () => {
		let state = {
			...this.state,
		};
		state.active = {
			day: false,
			time: false,
			diner: false,
			contact: false,
			legalInformation: false,
		};

		state.menu = {
			day: '',
			time: '',
			nbGuest: '',
			contact: '',
			send: '',
		};
		state.mobilePeriod = '';

		this.setState(state, this.saveStateToLocalStorage());
	};

	onUpdateLegalInformation = () => {
		this.setState({
			displayMenu: false,
		});
	};

	orderIsBooked = response => {
		if (!!response && response[0] === 'success') {
			let state = {
				...this.state,
			};
			state.active.legalInformation = true;
			this.setState(state);
		}
	};

	render() {
		const desktop = (
			<Router>
				<div id="client">
					<AdminInfo />
					<Menu
						className=""
						menu={this.state.menu}
						active={this.state.active}
						restaurantId={this.state.restaurantId}
						display={this.state.displayMenu}
					/>
					<div className="">
						<Switch>
							<Redirect exact from="/" to="/date" />
							<PropsRoute
								path="/date"
								component={Template(PickDate, {
									classNames: 'date',
								})}
								onUpdate={this.onUpdateDate}
								closingDays={this.state.info.closingDays}
								openingHours={this.state.info.openingHours}
								userDay={this.state.menu.day}
								mobilePeriod={this.state.mobilePeriod}
								onUpdateMobileDatePeriod={this.onUpdateMobileDatePeriod}
								validationDelay={this.state.info.validationDelay}
								closeDay={this.state.closeDay}
								disableAppPeriod={this.state.info.disableAppPeriod}
								disableApp={this.state.info.disableApp}
								disableMobilePeriod={this.state.info.disableMobilePeriod}
							/>

							<PropsRoute
								path="/heure"
								component={Template(PickTime, {
									classNames: 'is-flex is-parent',
								})}
								onUpdate={this.onUpdateTime}
								userTime={this.state.menu.time}
								openingHours={this.state.info.openingHours}
								userDay={this.state.menu.day}
								mobilePeriod={this.state.mobilePeriod}
								validationDelay={this.state.info.validationDelay}
								onUpdateMenuTime={this.onUpdateMenuTime}
								disableAppPeriod={this.state.info.disableAppPeriod}
								disableApp={this.state.info.disableApp}
								slotFreeze={this.state.slot_freeze || {}}
							/>
							<PropsRoute
								path="/couverts"
								component={Template(NbGuest, {
									classNames: 'is-flex is-parent',
								})}
								onUpdate={this.onUpdateNbGuest}
								userDiner={this.state.menu.nbGuest}
								maxGuests={this.state.info.maxGuests}
							/>
							<PropsRoute
								path="/contact"
								component={Template(Contact, {
									classNames: 'contact-content',
								})}
								onUpdate={this.onUpdateContact}
								val={this.state.contact}
								menu={this.state.menu}
								restaurantId={this.state.info.restaurantId}
								nameRestaurant={this.state.info.nameRestaurant}
							/>
							<PropsRoute
								path="/legalInformation"
								component={Template(LegalInformation, {
									classNames: 'contact-content',
								})}
								val={this.state.contact}
								menu={this.state.menu}
								restaurantId={this.state.info.restaurantId}
								nameRestaurant={this.state.info.nameRestaurant}
								specificInformation={this.state.info.specificInformation}
								timezone={this.state.info.timezone}
								orderIsBooked={this.orderIsBooked}
								onUpdate={this.onUpdateLegalInformation}
							/>
							<PropsRoute
								path="/validation"
								component={Validation}
								nameRestaurant={this.state.info.nameRestaurant}
								sendBooking={this.sendBooking}
							/>
						</Switch>
					</div>
				</div>
			</Router>
		);

		if (this.state.error === 'probleme') {
			return <NotFound />;
		} else if (
			!!this.state.error ||
			(this.state.info.disableApp &&
				this.state.info.disableAppPeriod &&
				this.state.info.disableAppPeriod.date === 'all') ||
			(this.state.info.disableApp &&
				this.state.info.disableAppPeriod &&
				this.state.info.disableAppPeriod.date ==
					moment(new Date()).format('YYYY-MM-DD'))
		) {
			return (
				<BeingCreated
					name={this.state.info.nameRestaurant}
					email={this.state.info.email}
					phone={this.state.info.phone}
				/>
			);
		} else {
			return (
				<Router>
					<div id="client">
						<Header
							info={this.state.info}
							slogan={this.state.width >= 769 ? true : false}
						/>
						{this.state.width >= 769 ? (
							desktop
						) : (
							<TemplateMobile
								info={this.state.info}
								mobilePeriod={this.state.mobilePeriod}
								closeDay={this.state.closeDay}
								menu={this.state.menu}
								active={this.state.active}
								displayMenu={this.state.displayMenu}
								onUpdateDate={this.onUpdateDate}
								onUpdateMobileDatePeriod={this.onUpdateMobileDatePeriod}
								onUpdateTime={this.onUpdateTime}
								onUpdateNbGuest={this.onUpdateNbGuest}
								onUpdateContact={this.onUpdateContact}
								onUpdateLegalInformation={this.onUpdateLegalInformation}
								sendBooking={this.sendBooking}
								specificInformation={this.state.specificInformation}
								timezone={this.state.info.timezone}
								maxGuests={this.state.info.maxGuests}
								orderIsBooked={this.orderIsBooked}
								slotFreeze={this.state.slot_freeze || {}}
							/>
						)}
					</div>
				</Router>
			);
		}
	}
}

export default IndexUser;
